import { render, staticRenderFns } from "./C2CWithdrawal.vue?vue&type=template&id=8d07e558&scoped=true&"
import script from "./C2CWithdrawal.vue?vue&type=script&lang=js&"
export * from "./C2CWithdrawal.vue?vue&type=script&lang=js&"
import style0 from "./C2CWithdrawal.vue?vue&type=style&index=0&id=8d07e558&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d07e558",
  null
  
)

export default component.exports